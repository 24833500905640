import React, { useEffect } from 'react'
import 'twin.macro'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import { useMeasurements } from 'content-queries/mx/new/measurements'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import { MainBanner, MainContent } from 'mx/components/new'
import { Measurements } from 'mx/components/new/measurements'
import Layout from 'mx/layout'

const MeasurementsPage = ({ path }: { path: string }) => {
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Measurements',
    })
  }, [])

  const { data } = useMeasurements()

  const [
    {
      node: {
        sectionModules: [hero, installation, banner],
      },
    },
  ] = data

  return (
    <Layout path={path}>
      <AutomaticPopup />
      <MainContent header dark data={hero} />
      <Measurements data={installation} classname={tw`mt-20 lg:mt-32`} />
      <MainBanner data={banner} classname={tw`mt-20 lg:mt-32`} />
    </Layout>
  )
}

export default MeasurementsPage

export const Head = () => {
  const { data } = useMeasurements()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/measurements"
      title={title}
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}
